import {
  type FC,
  useMemo,
  useRef,
} from 'react';
import { cn } from '@/lib/classNames';
import { Portal } from '@/components/ui/Portal';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import { type AbsolutePosition } from './PopUp.typedefs';
import styles from './PopUp.module.scss';

interface Props {
  position: AbsolutePosition;
  onClose: () => void;
  portalNode?: HTMLElement;
  className?: string;
  dataQa?: string;
}

export const PopUp: FC<Props> = ({
  onClose,
  position,
  portalNode,
  children,
  className = '',
  dataQa,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(containerRef, onClose);

  const animationClassName = useMemo(() => {
    if ('bottom' in position && !('top' in position)) {
      return styles.appearFromBottom;
    }

    return styles.appearFromTop;
  }, [position]);

  return (
    <Portal portalNode={portalNode}>
      <div className={styles.coverScreen}>
        <div
          className={
            cn(
              styles.popUp,
              animationClassName,
              className,
            )
          }
          style={{
            ...position,
          }}
          ref={containerRef}
          data-qa={dataQa}
        >
          {children}
        </div>
      </div>
    </Portal>
  );
};
